import { FooterComponent, FooterLinks } from './Footer.styled';
import { useFooter } from './useFooter';
import { SideEffect } from './sideEffects/SideEffects';
import { ReactComponent as ArrowDouble } from '../../../../assets/icon/arrowDouble.svg';
import { Button } from '../../button/Button';
import theme from '../../../../theme/theme';
import React from 'react';

export const Footer = () => {
  const { sideEffectHandler, isModalOpened, closeModal } = useFooter();

  return (
    <FooterComponent>
      <FooterLinks>
        <a href='https://www.roche.pl/pl/contact-us.html' target={'_blank'} rel='noreferrer'>
          Kontakt
        </a>
        <span>|</span>
        <a href={require('./../../../../../src/files/policy.pdf')} target={'_blank'} rel='noreferrer'>
          Polityka Prywatności
        </a>
        <span>|</span>
        <a href={require('./../../../../../src/files/rules.pdf')} target={'_blank'} rel='noreferrer'>
          Regulamin Serwisu
        </a>
        <span>|</span>
        <a href={'tel:+48601227095'} target={'_blank'} rel='noreferrer' style={{ textDecoration: 'none' }}>
          Pomoc techniczna: <b>601-227-095</b> 8:30 - 15:30
        </a>
        <span>|</span>
      </FooterLinks>
      <div>
        <Button
          backgroundColor={theme.colors.blue3}
          color={'white'}
          padding={'16px'}
          text={'Zgłoś działanie niepożądane'}
          size={'small'}
          width={'100%'}
          maxWidth={'370px'}
          icon={<ArrowDouble />}
          clickHandler={sideEffectHandler}
        />
      </div>
      {isModalOpened && <SideEffect closeModal={closeModal} />}
    </FooterComponent>
  );
};
